// modules
import gsap from 'gsap';

// app
import { app } from '../../utils/store'

export default class Hero {
  constructor() {
    this.section = {
      el: document.querySelector('.s-hero'),
      images: document.querySelectorAll('.hero-image'),
      title: document.querySelectorAll('.s-hero svg path'),
    }
  }

  init() {
    gsap.ticker.add(this.inview);

    if (app.loader) {
      this.animate();
    }
  }

  destroy = () => {
    gsap.ticker.remove(this.inview);
  }

  animate = () => {
    gsap.set(this.section.title, {
      y: window.innerWidth * 0.2,
    });

    gsap.to(this.section.title, {
      y: 0,
      delay: 2.5,
      duration: 3,
      stagger: 0.05,
      ease: 'expo.inOut',
    });
  }

  inview = () => {
    const py = Math.abs(app.scroll.data.last);
    const gb = this.section.el.getBoundingClientRect();

    const d = (window.innerHeight + py) - (gb.top + py);
    const p = d / ((window.innerHeight + gb.height) / 100);

    if (p > 0 && p < 100) {
      this.transform(py);
    }
  }

  transform = (py) => {
    this.section.images[0].style.transform = `translate3d(0px, ${-py / 5}px, 0px)`;
    // this.section.images[1].style.transform = `translate3d(0px, ${-py / 5}px, 0px)`;
    this.section.images[2].style.transform = `translate3d(0px, ${-py / 8}px, 0px)`;
    this.section.images[3].style.transform = `translate3d(0px, ${-py / 3}px, 0px)`;
  }
}
