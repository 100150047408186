export default class Text {
  constructor() {
    this.section = {
      el: document.querySelectorAll('.s-text'),
    }
  }

  init() {
    document.fonts.ready.then(() => {
      [...this.section.el].forEach((e) => {
        this.set(e);
      });
    });
  }

  destroy = () => {}

  set = (e) => {}
}
