// modules
import gsap from 'gsap';

export default class Popup {
  constructor() {
    this.popup = {
      el: document.querySelector('.a-popup'),
      close: document.querySelector('.popup-close'),
      background: document.querySelector('.popup-background'),
    }
  }

  init = () => {
    this.events();
    this.enter();
  }

  events = () => {
    this.popup.close.addEventListener('click', this.leave);
    this.popup.background.addEventListener('click', this.leave);
  }

  removeEvents = () => {
    this.popup.close.removeEventListener('click', this.leave);
    this.popup.background.removeEventListener('click', this.leave);
  }

  enter = () => {
    gsap.fromTo(this.popup.el, {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1,
      delay: 5,
    });
  }

  leave = () => {
    this.removeEvents();

    gsap.to(this.popup.el, {
      autoAlpha: 0,
      duration: 1,
      onComplete: () => {
        this.popup.el.remove();
      }
    });
  }
}
