// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';
import ScrollTrigger from 'gsap/ScrollTrigger';

// app
import { app } from '../../utils/store'

// register
gsap.registerPlugin(ScrollTrigger);

// store
import { math } from '../../utils/store';

export default class Scroll {
  constructor() {
    this.section = document.querySelector('.a-scroll');

    this.data = {
      last: 0,
      ease: 0.1,
      current: 0,
    };
  }

  render = () => {
    if (Sniffer.isDevice) {
      this.data.current = this.section.getBoundingClientRect().top;
    };

    if (app.loading === true) return;

    this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease);

    this.data.abs = Math.abs(this.data.last);

    if (this.data.abs < 0.1) {
      this.data.last = 0;
    }

    // gsap trigger
    ScrollTrigger.update();

    if (Sniffer.isDevice) return;

    // body
    this.section.style.transform = `translate3d(0px, ${this.data.last}px, 0px)`;
  }

  calc = (e) => {
    if (app.loading === true) return;

    this.data.current += -e.deltaY;
    this.data.current = Math.max((this.bounding.height - window.innerHeight) * -1, this.data.current);
    this.data.current = Math.min(0, this.data.current);
  }

  resize = () => {
    this.bounding = this.section.getBoundingClientRect();
  }

  events = () => {
    window.addEventListener('resize', this.resize, { passive: true });
  }

  on = () => {
    if (Sniffer.isDesktop) {
      document.body.addEventListener('wheel', this.calc);
    }
    gsap.ticker.add(this.render);
  }

  off = () => {
    gsap.ticker.remove(this.render);

    document.body.removeEventListener('wheel', this.calc);

    this.data.last = undefined;
    this.data.current = undefined;

    window.removeEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    this.off();
  }

  trigger = () => {
    ScrollTrigger.scrollerProxy(this.section, {
      scrollTop(value) {
        return arguments.length ? app.scroll.data.current = -value : Math.abs(app.scroll.data.last);
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
      },
      pinType: 'transform'
    });

    ScrollTrigger.defaults({
      scroller: this.section,
      start: 'top bottom',
      end: 'bottom top',
    });
  }

  init = () => {
    imagesLoaded(this.section, () => {
      this.bounding = this.section.getBoundingClientRect();

      this.on();
      this.events();
      this.trigger();
    });
  }
}
