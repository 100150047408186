// store
import { app } from '../utils/store';

// components
import Collage from '../components/collage/collage';
import Header from '../components/header/header';
import Hero from '../components/hero/hero';
import Image from '../components/image/image';
import Images from '../components/images/images';
import Next from '../components/next/next';
import Scroll from './scroll/scroll';
import Text from '../components/text/text';
import TextImage from '../components/text-image/text-image';
import Work from '../components/work/work';

export default class Components {
  constructor() {
    this.collage = document.querySelector('.s-collage');
    this.header = document.querySelector('.s-header');
    this.hero = document.querySelector('.s-hero');
    this.image = document.querySelector('.s-image');
    this.images = document.querySelector('.s-images');
    this.next = document.querySelector('.s-next');
    this.text = document.querySelector('.s-text');
    this.textImage = document.querySelector('.s-text-image');
    this.work = document.querySelector('.s-work');
  }

  destroy = () => {
    if (this.collage) { this.collage.destroy(); }
    if (this.header) { this.header.destroy(); }
    if (this.hero) { this.hero.destroy(); }
    if (this.image) { this.image.destroy(); }
    if (this.images) { this.images.destroy(); }
    if (this.next) { this.next.destroy(); }
    if (this.text) { this.text.destroy(); }
    if (this.textImage) { this.textImage.destroy(); }
    if (this.work) { this.work.destroy(); }
  }

  init = () => {
    app.scroll = new Scroll();
    app.scroll.init();

    if (this.collage) {
      this.collage = new Collage();
      this.collage.init();
    }
    if (this.header) {
      this.header = new Header();
      this.header.init();
    }
    if (this.hero) {
      this.hero = new Hero();
      this.hero.init();
    }
    if (this.image) {
      this.image = new Image();
      this.image.init();
    }
    if (this.images) {
      this.images = new Images();
      this.images.init();
    }
    if (this.next) {
      this.next = new Next();
      this.next.init();
    }
    if (this.text) {
      this.text = new Text();
      this.text.init();
    }
    if (this.textImage) {
      this.textImage = new TextImage();
      this.textImage.init();
    }
    if (this.work) {
      this.work = new Work();
      this.work.init();
    }
  }
}