// styles
import './index.scss';

// highway
import Highway from '@dogstudio/highway';

// renderers
import Renderer from './renderers/renderer';

// transitions
import Default from './transitions/default';
import Menu from './transitions/menu';
import Next from './transitions/next';
import Work from './transitions/work';

// ui
import Breadcrumb from './components/breadcrumb/breadcrumb';
import Cursor from './components/cursor/cursor';
import Loader from './components/loader/loader';
import Nav from './components/nav/nav';
import Popup from './components/popup/popup';

// sniffer
import Sniffer from 'sniffer';

// app
import { app } from './utils/store';

// init
app.breadcrumb = new Breadcrumb();
app.breadcrumb.init();

app.cursor = new Cursor();
app.cursor.init();

app.loader = new Loader();
app.loader.init();

app.nav = new Nav();
app.nav.init();

app.popup = new Popup();
app.popup.init();

// sniffer
Sniffer.addClasses(document.documentElement);

// core
app.highway = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Default,
    contextual: {
      next: Next,
      work: Work,
      menu: Menu
    }
  }
});

// viewport
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// menu
app.highway.on('NAVIGATE_IN', ({ to, location }) => {
  app.nav.set();
});

app.highway.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
  app.breadcrumb.set();
});

// serviceworker
const sw = `${location.origin}/sw.js`;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(sw).then(registration => {
  }).catch(error => {
    console.log(error);
  });
}
