// modules
import Highway from '@dogstudio/highway';

// utils
import Effects from '../utils/effects';

// components
import Components from '../components/index';

// app
import { app } from '../utils/store';

class Renderer extends Highway.Renderer {
  onEnter() {
    this.effects = new Effects();
    this.effects.init();
  }

  onEnterCompleted() {
    if (app.scroll) {
      app.scroll.destroy();
    }

    this.components = new Components();
    this.components.init();

    app.cursor.events();
  }

  onLeave() {
    app.loading = true;
    app.cursor.destroy();
  }

  onLeaveCompleted() {
    this.components.destroy();
    this.components = undefined;

    this.effects.destroy();
    this.effects = undefined;
  }
}

// export
export default Renderer;
