// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';

// app
import { app } from '../../utils/store';

export default class Header {
  constructor() {
    this.page = document.querySelector('.a-page');

    this.type = {
      image: document.querySelector('.type-image'),
      project: document.querySelector('.type-project'),
    }

    this.header = {
      el: document.querySelector('.s-header'),
      intro: document.querySelector('.header-intro'),
      image: document.querySelector('.header-image img'),
    }

    this.data = {
      last: 0,
      ease: 0.1,
    };
  }

  init = () => {
    if (Sniffer.isDevice) return;

    if (this.type.image || this.type.project) {
      gsap.ticker.add(this.render);
    }

    if (this.type.project) {
      this.project();
    }
  }

  destroy = () => {
    if (this.type.image || this.type.project) {
      gsap.ticker.remove(this.render);
    }
  }

  project = () => {
    if (this.header.intro) {
      this.header.intro.remove();
    }
  }

  render = () => {
    if (this.type.image) {
      this.header.image.style.transform = `translate3d(0px, ${Math.abs(app.scroll.data.last / 4)}px, 0px)`;
    }

    if (this.type.project) {
      this.header.image.style.transform = `translate3d(0px, -${Math.abs(app.scroll.data.last / 6)}px, 0px)`;
    }
  }
}
