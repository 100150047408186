// modules
import gsap from 'gsap';

export default class Breadcrumb {
  constructor() {
    this.ui = {
      logo: document.getElementById('a-logo'),
      el: document.getElementById('a-breadcrumb'),
      menu: document.querySelectorAll('.nav-menu a'),
    }

    this.i = this.ui.el.querySelector('i');
  }

  init() {
    this.set();
    this.events();
  }

  events = () => {
    this.ui.el.addEventListener('click', this.hide);
    this.ui.logo.addEventListener('click', this.hide);
    [...this.ui.menu].forEach((e) => {
      e.addEventListener('click', this.hide);
    });
  }

  hide = () => {
    gsap.to(this.ui.el, {
      autoAlpha: 0,
    });
  }

  set = () => {
    const trigger = document.querySelector('.type-project');

    if (trigger) {
      const title = document.querySelector('.s-header .f-title-r').dataset.title;
      gsap.to(this.ui.el.querySelector('i'), {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.i.innerHTML = title;
        }
      })
      gsap.to(this.ui.el.querySelector('i'), {
        delay: 0.6,
        opacity: 1,
        duration: 0.5,
      })
      gsap.to(this.ui.el, {
        delay: 1.1,
        autoAlpha: 1,
        duration: 0.5,
      });
    } else {
      gsap.to(this.ui.el, {
        autoAlpha: 0,
        duration: 0.5,
      });
    }
  }
}
