// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';

// app
import { app } from '../../utils/store';

export default class Collage {
  constructor() {
    this.section = {
      el: document.querySelector('.s-collage'),
      images: document.querySelectorAll('.collage-image'),
    }

    this.position = {
      x: 0,
      y: 0,
    }

    this.visible = false;
  }

  init = () => {
    if (Sniffer.isDevice) return;

    this.events();
    gsap.ticker.add(this.inview);
  }

  destroy = () => {
    gsap.ticker.add(this.inview);
  }

  events = () => {
    this.section.el.addEventListener('mouseenter', () => {
      app.cursor.active = true;
    });

    this.section.el.addEventListener('mouseleave', () => {
      app.cursor.active = false;
    });

    [...this.section.images].forEach((i) => {
      i.addEventListener('mousedown', (e) => {
        this.section.el.style.zIndex = 9;
        this.position.x = e.pageX - e.target.offsetLeft;
        this.position.y = e.pageY - e.target.offsetTop;
        e.target.addEventListener('mousemove', this.move);
      });

      i.addEventListener('mouseup', (e) => {
        this.section.el.style.zIndex = 1;
        e.target.removeEventListener('mousemove', this.move);
      });
    });
  }

  inview = () => {
    const b = this.section.el.getBoundingClientRect();

    if (b.top < window.innerHeight) {
      if (this.visible === false) {
        this.visible = true;
      }
    } else {
      if (this.visible === true) {
        gsap.to(this.section.images, {
          top: 0,
          left: 0,
          duration: 2,
          ease: 'expo.inOut',
        });
        this.visible = false;
      }
    }
  }

  move = (e) => {
    e.target.style.top = `${e.pageY - this.position.y}px`;
    e.target.style.left = `${e.pageX - this.position.x}px`;
  }
}
