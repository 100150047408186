// modules
import gsap from 'gsap';
import Highway from '@dogstudio/highway';

// app
import { app } from '../utils/store';

class Menu extends Highway.Transition {
  in({ from, to, done }) {
    const overlay = to.querySelector('.a-overlay');

    this.buttons.forEach((el) => {
      el.classList.remove('is-trigger');
    });

    let y1;
    let y2;

    if (this.direction === 'next') {
      y1 = window.innerHeight / 2;
      y2 = -window.innerHeight;
    }

    if (this.direction === 'prev') {
      y1 = -window.innerHeight / 2;
      y2 = window.innerHeight;
    }

    gsap.set(overlay, {
      autoAlpha: 1,
    });

    gsap.set(to, {
      y: y1,
    });

    gsap.to(overlay, {
      duration: 2,
      autoAlpha: 0,
      ease: 'expo.inOut',
    });

    gsap.to(from, {
      y: y2,
      duration: 2,
      ease: 'expo.inOut',
    });

    gsap.to(to, {
      y: 0,
      duration: 2,
      ease: 'expo.inOut',
      onComplete: () => {
        from.remove();
        done();
        app.loading = false;
      }
    });
  }

  out({ from, done, trigger }) {
    this.incoming = undefined;
    this.outgoing = undefined;
    this.direction = undefined;

    trigger.classList.add('is-trigger');

    this.buttons = document.querySelectorAll('#a-nav a');

    this.buttons.forEach((el, index) => {
      if (el.classList.contains('is-active')) {
        this.outgoing = index;
      }
      if (el.classList.contains('is-trigger')) {
        this.incoming = index;
      }
    });

    if (this.outgoing < this.incoming) {
      this.direction = 'next';
    } else {
      this.direction = 'prev';
    }

    done();
  }
}

export default Menu;
