// modules
import Highway from '@dogstudio/highway';

// app
import { app } from '../utils/store';

class Default extends Highway.Transition {
  in({ from, to, done }) {
    from.remove();
    done();
    app.loading = false;
  }

  out({ from, done }) {
    done();
  }
}

export default Default;
