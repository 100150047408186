// modules
import gsap from 'gsap';
import lottie from 'lottie-web';

// app
import { app } from '../../utils/store';

export default class Loader {
  constructor() {
    this.section = {
      el: document.getElementById('a-loader'),
      monogram: document.querySelector('.loader-monogram'),
      wordmark: document.querySelector('.loader-wordmark'),
    }

    this.page = document.querySelector('.a-page');
    this.overlay = document.querySelector('.a-overlay');
  }

  init() {
    if (app.loading === false) return;
    setTimeout(() => {
      this.set();
    }, 500);
  }

  destroy = () => {
    app.loading = false;
    app.loader = undefined;
    this.section.el.remove();
  }

  set = () => {
    const letters = this.section.wordmark.querySelectorAll('path');

    gsap.set(this.overlay, {
      autoAlpha: 1,
    });

    gsap.set(this.page, {
      y: window.innerHeight / 2,
    });

    gsap.fromTo(letters, {
      opacity: 0,
      y: window.innerWidth * 0.03472,
    }, {
      y: 0,
      delay: 1.5,
      opacity: 1,
      duration: 2,
      stagger: 0.02,
      ease: 'expo.out',
    });

    const animation = lottie.loadAnimation({
      loop: false,
      autoplay: false,
      renderer: 'svg',
      container: this.section.monogram,
      path: `/wp-content/themes/aebele/src/json/logo.json`,
    });

    gsap.to(this.section.el, {
      delay: 2.5,
      duration: 2,
      ease: 'expo.inOut',
      y: -window.innerHeight,
    });

    gsap.to(this.page, {
      y: 0,
      delay: 2.5,
      duration: 2,
      ease: 'expo.inOut',
    });

    gsap.to(this.overlay, {
      delay: 2.5,
      duration: 2,
      autoAlpha: 0,
      onComplete: () => {
        this.destroy();
        app.scroll.resize();
      },
    });

    animation.play();
  }
}
