// modules
import gsap from 'gsap';

export default class Images {
  constructor() {
    this.section = {
      el: document.querySelectorAll('.s-images'),
    }

    this.tg = {
      el: document.querySelectorAll('.s-images.type-grid'),
    }

    this.l1 = {
      el: document.querySelectorAll('.s-images.type-duo.layout-1'),
    }

    this.l2 = {
      el: document.querySelectorAll('.s-images.type-duo.layout-2'),
    }

    this.l3 = {
      el: document.querySelectorAll('.s-images.type-duo.layout-3'),
    }
  }

  init() {
    setTimeout(() => {
      if (this.tg.el.length > 0) {
        [...this.tg.el].forEach((i) => {
          this.itg(i);
        });
      }
      if (this.l1.el.length > 0) {
        [...this.l1.el].forEach((i) => {
          this.il1(i);
        });
      }
      if (this.l2.el.length > 0) {
        [...this.l2.el].forEach((i) => {
          this.il2(i);
        });
      }
      if (this.l3.el.length > 0) {
        [...this.l3.el].forEach((i) => {
          this.il3(i);
        });
      }
    }, 250);
  }

  destroy = () => { }

  itg = (i) => {
    const section = i;
    const image = i.querySelectorAll('.images-wrap');

    gsap.fromTo(image[0], {
      y: -window.innerWidth * 0.03,
    }, {
      y: 0,
      duration: 2,
      ease: 'expo.out',
      scrollTrigger: {
        scrub: false,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
    gsap.fromTo(image[1], {
      x: -window.innerWidth * 0.03,
    }, {
      x: 0,
      duration: 2,
      ease: 'expo.out',
      scrollTrigger: {
        scrub: false,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
    gsap.fromTo(image[2], {
      x: window.innerWidth * 0.03,
    }, {
      x: 0,
      duration: 2,
      ease: 'expo.out',
      scrollTrigger: {
        scrub: false,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
  }

  il1 = (i) => {
    const section = i;
    const image = i.querySelectorAll('.images-wrap');

    const h1 = image[0].getBoundingClientRect().height;
    const h2 = image[1].getBoundingClientRect().height;

    gsap.fromTo(image[0], {
      y: 0,
    }, {
      y: h2 - h1,
      ease: 'none',
      scrollTrigger: {
        scrub: true,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
  }

  il2 = (i) => {
    const section = i;
    const image = i.querySelectorAll('.images-wrap');

    const h1 = image[0].getBoundingClientRect().height;
    const h2 = image[1].getBoundingClientRect().height;

    gsap.fromTo(image[1], {
      y: 0,
    }, {
      y: h1 - h2,
      ease: 'none',
      scrollTrigger: {
        scrub: true,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
  }

  il3 = (i) => {
    const section = i;
    const image = i.querySelectorAll('.images-wrap');

    gsap.fromTo(image[1], {
      y: window.innerWidth * 0.05,
    }, {
      y: 0,
      ease: 'cubic.out',
      scrollTrigger: {
        scrub: true,
        trigger: section,
        start: 'top center',
        end: 'bottom center',
      }
    });
  }
}
