// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Work {
  constructor() {
    this.section = {
      el: document.querySelector('.s-work'),
      image: document.querySelectorAll('.s-work .work-image'),
      project: document.querySelectorAll('.s-work .work-project'),
    }
  }

  init() {
    setTimeout(() => {
      [...this.section.project].forEach((i) => {
        this.animate(i);
      });
    }, 250);

    if (Sniffer.isPhone) {
      [...this.section.project].forEach((i) => {
        i.dataset.transition = 'next';
      });
    }
  }

  destroy = () => { }

  animate = (i) => {
    const section = i;
    const image = i.querySelector('.work-image');

    gsap.fromTo(image, {
      scale: 1,
    }, {
      scale: 1.1,
      ease: 'cubic.inOut',
      scrollTrigger: {
        scrub: true,
        trigger: section,
        start: 'top center',
      }
    });
  }
}
