// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';
import SplitText from 'gsap/SplitText';

// app
import { wrap } from '../../utils/store';

export default class Nav {
  constructor() {
    this.nav = {
      el: document.getElementById('a-nav'),
      close: document.querySelector('.nav-close'),
      links: document.querySelectorAll('#a-nav a'),
      menu: document.querySelectorAll('.nav-menu a'),
      indicator: document.querySelector('#a-nav svg'),
      contact: document.querySelectorAll('.nav-contact a'),
    }

    this.button = {
      menu: document.getElementById('a-menu'),
      close: document.querySelector('.nav-close'),
    }

    this.app = document.getElementById('app');

    this.visible = false;
  }

  init = () => {
    this.attributes();
    this.set();
    this.events();
  }

  attributes = () => {
    [...this.nav.links].forEach((i) => {
      i.dataset.transition = 'menu';
    });
  }

  set() {
    [...this.nav.links].forEach((i) => {
      i.classList.remove('is-active');
      if (i.href === location.href) {
        i.classList.add('is-active');
      }
    });

    if (Sniffer.isPhone) return;

    this.dark = document.querySelector('.s-header.type-image');

    [...this.nav.links].forEach((i) => {
      if (i.href === location.href) {
        const pb = this.nav.el.getBoundingClientRect().top;
        const ab = i.getBoundingClientRect().top;
        gsap.to(this.nav.indicator, {
          y: ab - pb,
          duration: 2,
          ease: 'expo.inOut',
        });
      }
    });
  }

  events = () => {
    if (Sniffer.isPhone) {
      this.button.menu.addEventListener('click', this.toggle);
      this.button.close.addEventListener('click', this.toggle);

      [...this.nav.links].forEach((e) => {
        e.addEventListener('click', this.toggle);
      });

      [...this.nav.menu].forEach((e) => {
        const split = new SplitText(e, {
          type: 'chars'
        });
        split.chars.forEach((e) => {
          wrap(e);
        });
      });

      return;
    }

    window.addEventListener('resize', this.resize, {
      passive: true
    });

    gsap.ticker.add(this.render);
  }

  toggle = () => {
    if (this.animating === true) return;
    this.animating = true;

    if (this.visible === false) {
      this.show();
    } else {
      this.hide();
    }
  }

  show = () => {
    this.nav.el.style.display = 'flex';

    setTimeout(() => {
      this.nav.el.classList.add('is-visible');
    }, 500);

    this.visible = true;

    [...this.nav.menu].forEach((e) => {
      const c = e.querySelectorAll('.cube');
      gsap.to(c, {
        delay: 0.2,
        stagger: 0.04,
        css: { className: '+=flip cube' },
      });
    });

    [...this.nav.contact].forEach((e) => {
      const c = e.querySelectorAll('svg path');
      gsap.fromTo(c, {
        opacity: 0,
        x: -window.innerHeight * 0.02,
      }, {
        x: 0,
        opacity: 1,
        delay: 0.5,
        duration: 2,
        stagger: -0.15,
        ease: 'expo.out',
      });
    });

    gsap.fromTo(this.nav.el.querySelectorAll('i'), {
      opacity: 0,
      y: window.innerHeight * 0.02,
    }, {
      y: 0,
      opacity: 1,
      delay: 0.5,
      duration: 2,
      stagger: 0.15,
      ease: 'expo.out',
    });

    gsap.fromTo(this.app, {
      x: 0,
    }, {
      duration: 1,
      ease: 'expo.inOut',
      x: -window.innerWidth / 2,
    });

    gsap.fromTo(this.nav.close, {
      opacity: 0,
    }, {
      delay: 0.5,
      opacity: 1,
      duration: 1,
    });

    gsap.fromTo(this.nav.el, {
      x: window.innerWidth,
    }, {
      x: 0,
      duration: 1,
      ease: 'expo.inOut',
      onComplete: () => {
        this.animating = false;
      }
    });
  }

  hide = () => {
    this.visible = false;

    [...this.nav.menu].forEach((e) => {
      const c = e.querySelectorAll('.cube');
      gsap.to(c, {
        stagger: -0.04,
        css: { className: '-=cube' },
      });
    });

    gsap.fromTo(this.app, {
      x: -window.innerWidth / 2,
    }, {
      x: 0,
      duration: 1,
      ease: 'expo.inOut',
    });

    gsap.fromTo(this.nav.el, {
      x: 0,
    }, {
      duration: 1,
      ease: 'expo.inOut',
      x: window.innerWidth,
      onComplete: () => {
        this.animating = false;
        this.nav.el.style.display = 'none';
        this.nav.el.classList.remove('is-visible');
      }
    });
  }

  render = () => {
    if (!this.dark) return;
    [...this.nav.links].forEach((i) => {
      this.intersect(i);
    });
  }

  intersect = (i) => {
    if (
      i.getBoundingClientRect().bottom < this.dark.getBoundingClientRect().top ||
      i.getBoundingClientRect().top > this.dark.getBoundingClientRect().bottom
    ) {
      i.classList.remove('is-light');
    } else {
      i.classList.add('is-light');
    }
  }

  resize = () => {
    [...this.nav.links].forEach((i) => {
      if (i.href === location.href) {
        const pb = this.nav.el.getBoundingClientRect().top;
        const ab = i.getBoundingClientRect().top;
        gsap.set(this.nav.indicator, {
          y: ab - pb,
        });
      }
    });
  }
}
