// modules
import gsap from 'gsap';

export default class Cursor {
  constructor() {
    this.cursor = {
      el: document.getElementById('a-cursor'),
      icon: document.querySelector('.cursor-icon'),
      circle: document.querySelector('.cursor-circle'),
    };

    this.tX = 0;
    this.tY = 0;

    this.pX = 0;
    this.pY = 0;

    this.active = false;
  }

  render = () => {
    if (this.active === false) return;

    this.pX += ((this.tX - this.pX) * 0.07);
    this.pY += ((this.tY - this.pY) * 0.07);

    this.cursor.el.style.transform = `translate3d(${this.pX}px, ${this.pY}px, 0)`;
  }

  destroy = () => { }

  init() {
    window.addEventListener('mousemove', (e) => {
      this.tX = e.clientX;
      this.tY = e.clientY;
    });

    gsap.set(this.cursor.circle, {
      scale: 0.01,
      transformOrigin: '50% 50%',
    });

    gsap.ticker.add(this.render);
  }

  events = () => {
    this.cta = {
      drag: document.querySelectorAll('[data-cursor="drag"]'),
    };

    [...this.cta.drag].forEach((i) => {
      i.addEventListener('mouseup', this.up);
      i.addEventListener('mousedown', this.down);
      i.addEventListener('mouseenter', this.enter);
      i.addEventListener('mouseleave', this.leave);
    });
  }

  down = () => {
    gsap.killTweensOf(this.cursor.circle);
    gsap.to(this.cursor.circle, {
      scale: 0.9,
      duration: 0.2,
      transformOrigin: '50% 50%',
    });
  }

  up = () => {
    gsap.killTweensOf(this.cursor.circle);
    gsap.to(this.cursor.circle, {
      scale: 1,
      duration: 0.2,
      transformOrigin: '50% 50%',
    });
  }

  enter = () => {
    gsap.killTweensOf(this.cursor.circle);
    gsap.to(this.cursor.circle, {
      scale: 1,
      duration: 0.8,
      transformOrigin: '50% 50%',
    });
  }

  leave = () => {
    gsap.killTweensOf(this.cursor.circle);
    gsap.to(this.cursor.circle, {
      scale: 0.01,
      duration: 0.3,
      transformOrigin: '50% 50%',
    });
  }
}
