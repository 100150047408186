// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class TextImage {
  constructor() {
    this.section = {
      el: document.querySelectorAll('.s-text-image'),
      a: document.querySelectorAll('.s-text-image a'),
    }
    this.l3 = {
      el: document.querySelectorAll('.s-text-image.layout-3'),
    }
  }

  init() {
    [...this.section.a].forEach((i) => {
      i.dataset.transition = 'next';
    });

    if (Sniffer.isPhone) return;

    setTimeout(() => {
      if (this.l3.el.length > 0) {
        [...this.l3.el].forEach((i) => {
          this.il3(i);
        });
      }
    }, 250);
  }

  destroy = () => { }

  il3 = (i) => {
    const section = i;
    const image = i.querySelector('img');

    gsap.fromTo(image, {
      y: 0,
    }, {
      y: window.innerWidth * 0.2,
      ease: 'cubic.inOut',
      scrollTrigger: {
        scrub: true,
        trigger: section,
        start: 'top 300',
        end: 'bottom 300',
      }
    });
  }
}
