// modules
import gsap from 'gsap';

export default class Image {
  constructor() {
    this.section = {
      el: document.querySelectorAll('.s-image'),
    }

    this.tf = {
      el: document.querySelectorAll('.s-image.type-fluid'),
    }

    this.tm = {
      el: document.querySelectorAll('.s-image.type-mirror'),
    }
  }

  init() {
    setTimeout(() => {
      if (this.tf.el.length > 0) {
        [...this.tf.el].forEach((i) => {
          this.itf(i);
        });
      }
      if (this.tm.el.length > 0) {
        [...this.tm.el].forEach((i) => {
          this.itm(i);
        });
      }
    }, 250);
  }

  destroy = () => { }

  itf = (i) => {
    const section = i;
    const image = i.querySelector('img');

    gsap.fromTo(image, {
      scale: 1.3,
    }, {
      scale: 1,
      ease: 'cubic.out',
      scrollTrigger: {
        scrub: true,
        trigger: section,
      }
    });
  }

  itm = (i) => {
    const section = i;
    const image = i.querySelectorAll('img');

    gsap.fromTo(image[0], {
      scale: 1.1,
    }, {
      scale: 1,
      ease: 'linear',
      scrollTrigger: {
        scrub: true,
        trigger: section,
      }
    });

    gsap.fromTo(image[1], {
      scale: 1,
    }, {
      scale: 1.1,
      ease: 'linear',
      scrollTrigger: {
        scrub: true,
        trigger: section,
      }
    });
  }
}
