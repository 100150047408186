// modules
import gsap from 'gsap';
import Highway from '@dogstudio/highway';

// app
import { app } from '../utils/store';

class Next extends Highway.Transition {
  in({ from, to, done }) {
    const overlay = to.querySelector('.a-overlay');

    gsap.set(overlay, {
      autoAlpha: 1,
    });

    gsap.set(to, {
      y: window.innerHeight / 2,
    });

    gsap.to(overlay, {
      duration: 2,
      autoAlpha: 0,
      ease: 'expo.inOut',
    });

    gsap.to(from, {
      duration: 2,
      ease: 'expo.inOut',
      y: -window.innerHeight,
    });

    gsap.to(to, {
      y: 0,
      duration: 2,
      ease: 'expo.inOut',
      onComplete: () => {
        from.remove();
        done();
        app.loading = false;
      }
    });
  }

  out({ from, done }) {
    done();
  }
}

export default Next;
