// modules
import gsap from 'gsap';
import imagesLoaded from 'imagesloaded';
import Highway from '@dogstudio/highway';

// app
import { app } from '../utils/store';

class Work extends Highway.Transition {
  in({ from, to, done }) {
    const page = to;

    const header = page.querySelector('.s-header');
    const intro = page.querySelector('.header-intro');
    const heading = page.querySelector('.header-heading');
    const image = page.querySelector('.header-image img');
    const images = page.querySelectorAll('.header-intro img');

    header.classList.add('u-intro');

    imagesLoaded(page, () => {
      gsap.set(to, {
        zIndex: 3,
        y: window.innerHeight,
      });

      gsap.set([images, image, heading], {
        y: window.innerHeight,
      });

      gsap.to(to, {
        y: 0,
        duration: 2,
        ease: 'expo.inOut',
      });

      gsap.to(heading, {
        y: 0,
        delay: 1,
        duration: 2,
        ease: 'expo.inOut',
      });

      gsap.to([images, image], {
        y: 0,
        delay: 0.1,
        duration: 2,
        stagger: 0.2,
        ease: 'expo.inOut',
        onComplete: () => {
          intro.remove();
          from.remove();
          done();
          app.loading = false;
        }
      });
    });
  }

  out({ from, done }) {
    done();
  }
}

export default Work;
