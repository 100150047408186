// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';
import lottie from 'lottie-web';

export default class Images {
  constructor() {
    this.section = {
      el: document.querySelector('.s-next'),
      a: document.querySelector('.s-next a'),
      hr: document.querySelector('.s-next hr'),
      heading: document.querySelector('.s-next .f-heading-l'),
    }

    this.animation = undefined;
    this.animating = false;
  }

  init() {
    if (Sniffer.isPhone) return;
    this.set();
    this.events();
  }

  destroy = () => { }

  events = () => {
    this.section.a.addEventListener('mouseenter', this.enter);
    this.section.a.addEventListener('mouseleave', this.leave);
  }

  set = () => {
    const svg = document.createElement('div');
    const path = this.section.heading.dataset.next;

    svg.classList.add('is-svg');

    this.section.a.appendChild(svg);

    this.section.svg = document.querySelector('.s-next .is-svg');

    this.animation = lottie.loadAnimation({
      loop: false,
      container: svg,
      autoplay: false,
      renderer: 'svg',
      path: `/wp-content/themes/aebele/src/json/${path}.json`,
    });

    this.animation.addEventListener('complete', this.reset);

    gsap.set(this.section.heading, {
      color: '#C19A5B',
    });
  }

  enter = () => {
    if (this.animating === true) return;
    this.animating = true;

    gsap.set(this.section.svg, {
      opacity: 1,
    });

    gsap.set(this.section.hr, {
      transformOrigin: 'right',
    })

    gsap.to(this.section.heading, {
      duration: 1,
      color: '#34342E',
    });

    gsap.to(this.section.hr, {
      scaleX: 0,
      duration: 1,
      ease: 'expo.out',
    });

    this.animation.play();
  }

  leave = () => {
    if (this.animating === true) return;
    this.animating = true;

    gsap.to(this.section.heading, {
      duration: 1,
      color: '#C19A5B',
    });

    gsap.set(this.section.hr, {
      transformOrigin: 'left',
    });

    gsap.to(this.section.svg, {
      opacity: 0,
      duration: 1,
    });

    gsap.to(this.section.hr, {
      scaleX: 1,
      delay: 0.5,
      duration: 1,
      ease: 'expo.out',
      onComplete: () => {
        this.animation.stop();
        this.animating = false;
      }
    });
  }

  reset = () => {
    this.animating = false;
  }
}
