// modules
import gsap from 'gsap';
import Sniffer from 'sniffer';
import lottie from 'lottie-web';
import SplitText from 'gsap/SplitText';

// app
import { app, wrap, stagger } from './store';

export default class Effects {
  constructor() {
    this.page = document.querySelectorAll('.a-page');

    this.scripts = [];
    this.banner = [];
  }

  init() {
    if (this.page.length === 2) {
      this.page = this.page[1];
    } else {
      this.page = this.page[0];
    }

    this.effect = {
      text: this.page.querySelectorAll('[data-text]'),
      banner: this.page.querySelectorAll('[data-banner]'),
      background: this.page.querySelectorAll('[data-bg]'),
      button: this.page.querySelectorAll('[data-button]'),
      stagger: this.page.querySelectorAll('[data-stagger]'),
    }

    document.fonts.ready.then(() => {
      [...this.effect.button].forEach((e) => {
        this.setButton(e);
      });
      [...this.effect.text].forEach((e) => {
        this.setText(e);
      });
      [...this.effect.banner].forEach((e) => {
        this.setBanner(e);
      });
      [...this.effect.stagger].forEach((e) => {
        this.setStagger(e);
      });
    });

    gsap.ticker.add(this.render);
  }

  render = () => {
    this.animateBg();

    if (app.loading === true) return;

    this.animateText();
    this.animateBanner();
    this.animateStagger();
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  // buttons
  setButton = (e) => {
    if (Sniffer.isDevice) return;

    const button = e.querySelector('span');
    const split = new SplitText(button, {
      type: 'lines,words,chars'
    });

    split.lines.forEach((e) => {
      e.classList.add('f-line');
      e.removeAttribute('style');
    });

    split.chars.forEach((e) => {
      e.classList.add('f-char');
    });

    e.addEventListener('mouseenter', this.enterButton);
    e.addEventListener('mouseleave', this.leaveButton);

    setTimeout(() => {
      button.style.display = 'flex';
    }, 50);
  }

  enterButton = (e) => {
    const words = e.target.querySelectorAll('.f-line');
    const arrow = e.target.querySelectorAll('svg path');

    if (arrow.length > 0) {
      gsap.to(arrow, {
        x: 10,
        opacity: 0,
        duration: 1,
        force3D: true,
        stagger: -0.05,
        ease: 'expo.out',
      });
    }

    if (Sniffer.isFirefox) return;

    gsap.fromTo(words[0].querySelectorAll('.f-char'), {
      opacity: 1,
    }, {
      opacity: 0,
      duration: 1,
      stagger: 0.01,
      ease: 'expo.out',
    });

    gsap.fromTo(words[1].querySelectorAll('.f-char'), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      stagger: 0.01,
      ease: 'expo.out',
    });

    words.forEach((e) => {
      gsap.to(e.querySelectorAll('.f-char'), {
        duration: 1,
        force3D: true,
        stagger: 0.01,
        yPercent: -100,
        ease: 'expo.out',
      });
    });
  }

  leaveButton = (e) => {
    const words = e.target.querySelectorAll('.f-line');
    const arrow = e.target.querySelectorAll('svg path');

    if (arrow.length > 0) {
      gsap.killTweensOf(arrow);
      gsap.set(arrow, {
        x: -10,
        opacity: 0,
        force3D: true,
      });
      gsap.to(arrow, {
        x: 0,
        opacity: 1,
        duration: 1,
        force3D: true,
        stagger: -0.1,
        ease: 'expo.out',
      });
    }

    if (Sniffer.isFirefox) return;

    gsap.fromTo(words[0].querySelectorAll('.f-char'), {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      stagger: 0.01,
      ease: 'expo.out',
    });

    gsap.fromTo(words[1].querySelectorAll('.f-char'), {
      opacity: 1,
    }, {
      opacity: 0,
      duration: 1,
      stagger: 0.01,
      ease: 'expo.out',
    });

    words.forEach((e) => {
      gsap.to(e.querySelectorAll('.f-char'), {
        yPercent: 0,
        duration: 1,
        stagger: 0.01,
        force3D: true,
        ease: 'expo.out',
      });
    });
  }

  // stagger
  setStagger = (e) => {
    const split = new SplitText(e, {
      type: 'lines,words'
    });

    split.lines.forEach((e) => {
      stagger(e);
    });

    const line = e.querySelectorAll('.line > div');

    gsap.set(line, {
      opacity: 0,
      yPercent: 100,
    });

    const header = e.closest('.s-header');

    if (header) {
      let delay;

      if (header.classList.contains('u-intro')) {
        delay = 2.5;
      } else {
        delay = 1.2;
      }

      if (app.loader) {
        delay = 4.2;
      }

      gsap.to(line, {
        opacity: 1,
        yPercent: 0,
        duration: 2,
        stagger: 0.1,
        delay: delay,
        ease: 'expo.out'
      });
    }
  }

  animateStagger = () => {
    [...this.effect.stagger].forEach((el) => {
      if (el.closest('.s-header')) {
        return;
      }

      const i = el;
      const b = el.getBoundingClientRect();

      if (b.top < window.innerHeight / 1.2 && el.inview === undefined) {
        i.inview = true;

        const c = i.querySelectorAll('.line > div');

        gsap.to(c, {
          opacity: 1,
          yPercent: 0,
          duration: 2,
          stagger: 0.1,
          ease: 'expo.out'
        });
      }
    });
  }

  // text
  setText = (e) => {
    let svg = undefined;
    const path = e.dataset.text;

    if (path != 'none') {
      svg = document.createElement('div');

      e.appendChild(svg);

      const animation = lottie.loadAnimation({
        loop: false,
        container: svg,
        autoplay: false,
        renderer: 'svg',
        path: `/wp-content/themes/aebele/src/json/${path}.json`,
      });

      this.scripts.push(animation);
    }

    const split = new SplitText(e, {
      type: 'lines,words,chars'
    });

    split.lines.forEach((e) => {
      e.removeAttribute('style');
      e.classList.add('f-line');
    });

    split.words.forEach((e) => {
      e.removeAttribute('style');
    });

    split.chars.forEach((e) => {
      wrap(e);
    });

    setTimeout(() => {
      e.style.display = 'flex';
    }, 50);

    if (svg) {
      svg.closest('.f-line').classList.add('is-svg');
    }

    const header = e.closest('.s-header');

    if (header) {
      let delay;

      if (header.classList.contains('u-intro')) {
        delay = 2;
      } else {
        delay = 1;
      }

      if (app.loader) {
        delay = 4;
      }

      const c = e.querySelectorAll('.cube');

      setTimeout(() => {
        if (svg) {
          this.scripts[0].play();
        }
      }, delay * 1000);

      gsap.to(c, {
        delay: delay,
        stagger: 0.04,
        css: { className: '+=flip cube' }
      });
    }
  }

  animateText = () => {
    [...this.effect.text].forEach((el, index) => {
      if (el.closest('.s-header')) {
        return;
      }

      const i = el;
      const b = el.getBoundingClientRect();

      if (b.top < window.innerHeight && el.inview === undefined) {
        i.inview = true;

        const c = i.querySelectorAll('.cube');

        this.scripts[index].play();

        gsap.to(c, {
          stagger: 0.04,
          css: { className: '+=flip cube' }
        });
      }
    });
  }

  // banner
  setBanner = (e) => {
    const svg = document.createElement('div');
    const path = e.dataset.banner;

    e.appendChild(svg);

    const animation = lottie.loadAnimation({
      loop: false,
      container: svg,
      autoplay: false,
      renderer: 'svg',
      path: `/wp-content/themes/aebele/src/json/${path}.json`,
    });

    this.banner.push(animation);

    const split = new SplitText(e, {
      type: 'lines,words'
    });

    split.lines.forEach((e) => {
      e.removeAttribute('style');
      e.classList.add('f-line');
    });
  }

  animateBanner = () => {
    [...this.effect.banner].forEach((el, index) => {
      const i = el;
      const b = el.getBoundingClientRect();

      if (b.top < window.innerHeight && el.inview === undefined) {
        i.inview = true;
        this.banner[index].play();
      }
    });
  }

  // bg
  animateBg = () => {
    [...this.effect.background].forEach((el) => {
      const b = el.getBoundingClientRect();

      if (b.top < window.innerHeight && b.bottom > 0) {
        this.page.style.backgroundColor = el.dataset.bg;
      }
    });
  }
}
